import {BaseService} from './base/base.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JsonConvert} from 'json2typescript';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ModelHelper} from '../models/database/model-helper';
import { ODataResponse } from '../models/database/odata-response.model';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TblWorkerService {
  public jsonConvert: JsonConvert;
  constructor(private apiSrv: ApiService,   private http: HttpClient

    ) {
    this.jsonConvert = new JsonConvert();
  }

  getWokerByUserId(UserId : string) : Observable<Object> {
    return this.apiSrv.get(`TblWorkers?$filter=UserId eq '${UserId}'`).pipe(
      catchError(err => throwError(err)),
    );
  }
  getUser(UserId : string) : Observable<Object> {
    return this.apiSrv.get(`TblWorkers(${UserId})`).pipe(
      catchError(err => throwError(err)),
    );
  }
  getAllUser() : Observable<Object> {
    return this.apiSrv.get(`TblWorkers`).pipe(
      catchError(err => throwError(err)),
    );
  }
  addUsertoDataBase(formData : any) :  Observable<Object> {
    return this.apiSrv.post('TblWorkers',formData);
  }
  
  getSortOrder(): Observable<Object> {
    return this.apiSrv.get(`TblSortOrders`).pipe(
      catchError(err => throwError(err)),
    );
  }

  upateSortOrderIdForUSer(body: any, UserId: any): Observable<Object> {
    let url = `TblWorkers`;
    return this.apiSrv.patch(url, UserId, body).pipe(
      catchError(err => throwError(err)),
      map((res) => {
        return res;
      }
      ));
  }
  postRefreshToken(refreshtoken: any) {
    let datapost = "refresh_token=" + encodeURIComponent(refreshtoken) + "&grant_type=" +  encodeURIComponent('refresh_token');
    const options = {};
    options['headers'] = { 'Content-Type': 'application/x-www-form-urlencoded' }
    return this.http.post(`${environment.refresh_token}/token?key=AIzaSyBV-2Mwe5pQ--fE2mlAzLcE7107a_QOc54`, datapost, options);
  }
  getNameDatabase() : Observable<Object> {
    return this.apiSrv.get(`database`).pipe(
      catchError(err => throwError(err)),
    );
  }
}