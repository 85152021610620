export class QueryExpression {
    IdentityId: string = undefined;
    EntitySet: string = undefined;
    IsCount: boolean = undefined;
    Filter: string = undefined;
    Expand: string[] = [];
    Select: string[] = [];
    Skip: number = undefined;
    Top: number = undefined;
    OrderBy: string[] = [];
    OverrideHeader = true;
}
