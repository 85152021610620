import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import { ODataResponse } from '../models/odata-response.model';
import { QueryExpression } from '../models/query-expression.model';
import { Utils } from '../helpers/utils';
import { ModelHelper } from '../models/model-helper';
import { environment } from 'src/environments/environment';
import { DBSchema } from '../constants/db-schema';
import { UserLogged } from '../helpers/userlogged';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class ApiService {
  env = environment;
  public jsonConvert: JsonConvert;

  constructor(
    private http: HttpClient,
  ) {
    this.jsonConvert = new JsonConvert();
    // this.jsonConvert.operationMode = OperationMode.LOGGING; // print some debug data
    // this.jsonConvert.ignorePrimitiveChecks = true; // don't allow assigning number to string etc.
    // this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // never allow null
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  /**
   * Get record by id
   */
  // tslint:disable-next-line:max-line-length
  public getById(path: string, id: number, columnSet: string[] = [], expand: string[] = []): Observable<any> {
    // buid string columns need to select
    const queryExpression = new QueryExpression();
    queryExpression.Select = columnSet;
    queryExpression.Expand = expand;
    const odataQuery = Utils.buidODataQuery(queryExpression);
    return this.http.get(`${this.env.apiUrl}/${path}(${id})/${odataQuery}`).pipe(catchError(this.formatErrors));
  }

  /**
   * @param pathOrQueryExpression using path or QueryExpression to buid query odata
   */
  // tslint:disable-next-line:max-line-length
//   protected  getHeadersURLencoded() {
//  let header = {};
//  let userLogged: UserLogged = new UserLogged();
//  const helper = new JwtHelperService();
//  helper.isTokenExpired(userLogged.getToken());
//  if (userLogged.isLogged() && helper.isTokenExpired(userLogged.getToken()) == false) {
//    let header = {};
//    header['Authorization'] = 'Bearer ' + userLogged.getToken();
//    return header;
//  }
//  }
//   protected getHeaders(): Object {

//     let header = {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }; 
//     let userLogged: UserLogged = new UserLogged();
//     if (userLogged.isLogged()) {
//       header['Authorization'] = 'Bearer ' + userLogged.getToken();
//     }
//     return header;
//   }
  protected getHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  public getDataFromCustomFunction(pathOrQueryExpression: string | QueryExpression, options = {}): Observable<any> {
    const odataQuery = Utils.buidCustomDataQuery(pathOrQueryExpression);
    // options['headers'] = this.getHeadersURLencoded();
    return this.http.get(`${this.env.apiUrl}/${odataQuery}`, options).pipe(catchError(this.formatErrors));
  }

  public get(pathOrQueryExpression: string | QueryExpression, options = {}): Observable<any> {
    const odataQuery = Utils.buidODataQuery(pathOrQueryExpression);
    // options['headers'] = this.getHeadersURLencoded();
    return this.http.get(`${this.env.apiUrl}/${odataQuery}`, options).pipe(catchError(this.formatErrors));
  }

  public put(entitySet: string, id: number, body: Object = {}): Observable<any> {
    return this.http.put(`${this.env.apiUrl}/${entitySet}/${id}`, body).pipe(catchError(this.formatErrors));
  }

  public post(entitySet: string, body: Object = {}, options = {}): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/${entitySet}`, body, options).pipe(catchError(this.formatErrors));
  }

  public patch(entitySet: string, id: number, body: Object = {}): Observable<any> {
    return this.http.patch(`${this.env.apiUrl}/${entitySet}(${id})`, body).pipe(catchError(this.formatErrors));
  }

  public delete(entitySet: string, id: number): Observable<any> {
    return this.http.delete(`${this.env.apiUrl}/${entitySet}(${id})`).pipe(catchError(this.formatErrors));
  }

  public fakeDelete(entitySet: string, id: number): Observable<any> {
    const body = {
      Id: id,
      IsDeleted: true
    };
    return this.http.patch(`${this.env.apiUrl}/${entitySet}(${id})`, body).pipe(catchError(this.formatErrors));
  }

  /**
 * Get record by id, this function will return data after deserialize to correct type of model
 */
  // tslint:disable-next-line:max-line-length
  public getEntityById(entitySet: string, id: number, columnSet: string[] = [], expand: string[] = []): Observable<any> {
    // buid string columns need to select
    const queryExpression = new QueryExpression();
    queryExpression.Select = columnSet;
    queryExpression.Expand = expand;
    const odataQuery = Utils.buidODataQuery(queryExpression);
    return this.http.get(`${this.env.apiUrl}/${entitySet}(${id})/${odataQuery}`).
      pipe(
        catchError(this.formatErrors),
        map(res => this.jsonConvert.deserializeObject(res, ModelHelper.getClass(entitySet)))
      );
  }

  /**
   * Get record by QueryExpression, this function will return data after deserialize to correct type of model
   * @param queryExpression using path or QueryExpression to buid query odata
   */
  // tslint:disable-next-line:max-line-length
  public getEntities(queryExpression: QueryExpression, overrideHeader: boolean = true): Observable<any> {
    const odataQuery = Utils.buidODataQuery(queryExpression);
    const options = {};
    if (!overrideHeader) {
      const headers = new HttpHeaders();
      // MCBooks company whith id is 1 can get full data
      options['headers'] = headers.set('partnerid', '1');
    }
    return this.http.get(`${this.env.apiUrl}/${odataQuery}`, options).
      pipe(
        catchError(this.formatErrors),
        map(res => this.jsonConvert.deserializeObject(res, ODataResponse)),
        tap((res: ODataResponse) => res.value = this.jsonConvert.deserializeArray(res.value, ModelHelper.getClass(queryExpression.EntitySet)))
      );
  }

  /**
   * Get record by QueryExpression, this function will return data after deserialize to correct type of model
   * @param queryExpression using path or QueryExpression to buid query odata
   */
  // tslint:disable-next-line:max-line-length
  public getEntitiesByPartner(queryExpression: QueryExpression, partnerId: number): Observable<any> {
    const odataQuery = Utils.buidODataQuery(queryExpression);
    const options = {};
    const headers = new HttpHeaders();
    // MCBooks company whith id is 1 can get full data
    options['headers'] = headers.set('partnerid', partnerId.toString());
    return this.http.get(`${this.env.apiUrl}/${odataQuery}`, options).
      pipe(
        catchError(this.formatErrors),
        map(res => this.jsonConvert.deserializeObject(res, ODataResponse)),
        tap((res: ODataResponse) => res.value = this.jsonConvert.deserializeArray(res.value, ModelHelper.getClass(queryExpression.EntitySet)))
      );
  }

  /**
 * Get record by QueryExpression, this function will return data after deserialize to correct type of model
 * @param queryExpression using path or QueryExpression to buid query odata
 */
  // tslint:disable-next-line:max-line-length
  public getEntitiesPromise(queryExpression: QueryExpression): Promise<ODataResponse> {
    const odataQuery = Utils.buidODataQuery(queryExpression);
    return this.http.get(`${this.env.apiUrl}/${odataQuery}`).
      pipe(
        catchError(this.formatErrors),
        map(res => this.jsonConvert.deserializeObject(res, ODataResponse)),
        tap((res: ODataResponse) => res.value = this.jsonConvert.deserializeArray(res.value, ModelHelper.getClass(queryExpression.EntitySet)))
      ).toPromise();
  }

  /**
   * Upload files
   * @param filesToUpload
   */
  public postFiles(filesToUpload: File[]): Observable<any> {
    const formData: FormData = new FormData();
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append(`fileUpload${i}`, filesToUpload[i], filesToUpload[i]['name']);
    }
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      observe: 'events' as 'body',
      reportProgress: true,
    };
    return this.http.post(`${this.env.apiFileUrl}/upload`, formData, options).pipe(catchError(this.formatErrors));
  }

  /**
   * Upload files
   * @param filesToUpload
   */
  public importBookFromBravo(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(`file`, file, file.name);
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      observe: 'events' as 'body',
      reportProgress: true,
    };
    return this.http.post(`${this.env.apiExternalServiceUrl}/importBookFromBravo`, formData, options).pipe(catchError(this.formatErrors));
  }

  // /**
  //  * Delete files
  //  * @param filesToUpload
  //  */
  // public deleteFile(url: string): Observable<any> {
  //   const formData: FormData = new FormData();
  //   formData.append('url', url);
  //   const headers = new HttpHeaders();
  //   const options = {
  //     headers: headers,
  //     body: formData
  //   };
  //   return this.http.delete(`${this.env.apiFileUrl}/delete`, options);
  // }

  /**
   * Delete files
   * @param filesToUpload
   */
  public deleteFile(url: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('url', url);
    const headers = new HttpHeaders();
    const options = {
      headers: headers
    };
    return this.http.post(`${this.env.apiFileUrl}/delete`, formData, options);
  }

  public getExternal(functionName: string, options = {}): Observable<any> {
    return this.http.get(`${this.env.apiExternalServiceUrl}/${functionName}`, options).pipe(catchError(this.formatErrors));
  }

  public postExternal(functionName: string, body: Object = {}, options = {}): Observable<any> {
    return this.http.post(`${environment.apiExternalServiceUrl}/${functionName}`, body, options).pipe(catchError(this.formatErrors));
  }
  public postVerifyAddress(functionName: string, body: Object = {}, options = {}): Observable<any> {
    options['headers'] = { 'x-api-key': 'live_sk_7csD2xj8i9SczmtaeotEq7' , 'Content-Type': 'application/x-www-form-urlencoded'}
    return this.http.post(`${environment.apiPostGrid}/${functionName}`, body, options).pipe(catchError(this.formatErrors));
  }
  public postRefreshToken(entitySet: string, body: Object = {}): Observable<any> {
    const options = {};
    options['headers'] = { 'Content-Type': 'application/x-www-form-urlencoded' }
  //   'authorization' : ''
  // };
    // options['headers'] = this.getHeaders();
    return this.http.post(`${environment.refresh_token}/${entitySet}`, body, options).pipe(catchError(this.formatErrors));
  }
}
