import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '', 
    canActivate: [AuthGuard],
    component: PagesComponent,
    children:[
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // Redirect /dashboard to the default empty path
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },      
      { path: 'searchnote', loadChildren: () => import('./pages/searchnote/searchnote.module').then(m => m.SearchNoteModule) },
      // { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // Redirect /dashboard to the default empty path
    ]
  },
  { path: 'forgot-password', loadChildren: ()=> import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'register',  loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)  },
  { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  // preloadingStrategy: PreloadAllModules,  // <- uncomment this line for disable lazy load
  // useHash: true
});