import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { TblWorkerService } from 'src/services/tblWorker.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  nameDatabase : any;
  public settings: Settings;
  constructor(public appSettings:AppSettings, private tblWorkerService : TblWorkerService) {
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    let getDatabaseName = this.tblWorkerService.getNameDatabase().pipe(
      finalize(() => {
        getDatabaseName.unsubscribe();
      })
  ).subscribe(
      (res: any) => {
        // const obj = JSON.parse(res);
        // console.log(res)
        this.nameDatabase = res.DatabaseName;
      },
      (err) => {
          console.log(err);
      });
  }

}
