import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
// import { AuthenticationService } from '../services/authentication.service';
import { RouterURLContants } from '../constants/url.constants';
import { UserLogged } from './userlogged';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TblWorkerService } from 'src/services/tblWorker.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  refresh: boolean = false ;
  constructor(private router: Router, private tblWorkerService: TblWorkerService , private http: HttpClient) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('login') > -1 || request.url.indexOf('token?key') > -1 || request.url.indexOf('verifications?includeDetails') > -1) {
      return next.handle(request);
    }
    let userLogged: UserLogged = new UserLogged();
    let user = userLogged.getCurrentUser();
    // userLogged.logout();
    const helper = new JwtHelperService();
 if(userLogged.getToken()) {
  if (!helper.isTokenExpired(userLogged.getToken())) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${userLogged.getToken()}`
      }
    });
    return next.handle(request).pipe(catchError(err => {
      console.log(err);
      if (err.status === 401) {
      } else if (err.code == 400) {
         userLogged.logout();
         this.router.navigate(['/', 'login']);
      }
      // const error = err.error.message || err.statusText;
      return throwError(err);
    }));
  } else {
    return this.tblWorkerService.postRefreshToken(user.refresh_tokens).pipe(switchMap((token: any) => {
    userLogged.saveToken(token.id_token);
      return next.handle(this.addTokenHeader(request, token.id_token));  
    } ) ,
    catchError((err) => {
      return throwError(err);
    }))
  }
 }
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

    /* for Node.js Express back-end */
    return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
  }
}
