import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TblWorkerService } from 'src/services/tblWorker.service';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserLogged } from '../core/helpers/userlogged';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagesComponent implements OnInit {
    public showMenu:boolean = false;
    public showSetting:boolean = false;
    public menus = ['vertical', 'horizontal'];
    public menuOption:string;
    public menuTypes = ['default', 'compact', 'mini'];
    public menuTypeOption:string;
    
    public settings: Settings;
    constructor(public appSettings:AppSettings, public router:Router , private tblWorkerService : TblWorkerService){        
        this.settings = this.appSettings.settings; 
        if(sessionStorage["skin"]) {
            this.settings.theme.skin = sessionStorage["skin"];
        }     
    }

    ngOnInit() {        
        this.settings.theme.showMenu = false;
        // let userLogged: UserLogged = new UserLogged();
        // let user = userLogged.getCurrentUser()
        // const helper = new JwtHelperService();
        // if(userLogged.isLogged() && helper.isTokenExpired(userLogged.getToken()) == true ) {
        //     debugger;
        //     let refreshToken = this.tblWorkerService.postRefreshToken(user.refresh_tokens).pipe(
        //     finalize(() => {
        //         refreshToken.unsubscribe();
        //     })
        //   ).subscribe(
        //     (res: any) => {
        //       console.log(res)
        //       // let userLogged: UserLogged = new UserLogged();
        //       userLogged.setCurrentUser(res.id_token, user.userName, user.userId, user.refresh_tokens)
        //     //   location.reload();
        //       // this.ngOnInit();
        //     },
        //     (err) => {
        //       console.log(err);
        //     });
        // }
        if(window.innerWidth <= 768){
            this.settings.theme.showMenu = false;
            this.settings.theme.sideChatIsHoverable = false;
        }
        this.showMenu = this.settings.theme.showMenu;
        this.menuOption = this.settings.theme.menu;
        this.menuTypeOption = this.settings.theme.menuType;           
    }

    public chooseMenu(menu){
        this.settings.theme.menu = menu; 
        this.router.navigate(['/']);      
    }

    public chooseMenuType(menuType){
        this.settings.theme.menuType = menuType;
        jQuery('.menu-item-link').tooltip({
            sanitize: false,
            sanitizeFn: function (content) {
                return null;
            }
        });
        if(menuType=='mini'){
            jQuery('.menu-item-link').tooltip('enable');
        }else{
            jQuery('.menu-item-link').tooltip('disable');
        }
    }

    public changeTheme(theme){
        this.settings.theme.skin = theme;
        sessionStorage["skin"] = theme;        
    }
 
    ngAfterViewInit(){
        document.getElementById('preloader').classList.add('hide');
    }


    @HostListener('window:resize')
    public onWindowResize():void {
        let showMenu= !this._showMenu();

        if (this.showMenu !== showMenu) {
            this.showMenuStateChange(showMenu);
        }
        this.showMenu = showMenu;
    }

    public showMenuStateChange(showMenu:boolean):void {
        this.settings.theme.showMenu = showMenu;
    }

    private _showMenu():boolean {
        return false;
    }

}
