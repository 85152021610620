import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { UserLogged } from 'src/app/core/helpers/userlogged';
import { ODataResponse } from 'src/models/database/odata-response.model';
import { TblWorkerService } from 'src/services/tblWorker.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  public router: Router;
  username: string;
  userId: string;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute, private tblWorkerService: TblWorkerService) {
    this.router = router;
  }


  ngOnInit() {
    let userLogged: UserLogged = new UserLogged();
    if (userLogged.isLogged()) {
      // this.userLogin = true;
      let user = userLogged.getCurrentUser();
      // this.userId = user.userId;
      this.username = user.userName;
      // let getUser = this.tblWorkerService.getWokerByUserId(user.userName).pipe(
      //   finalize(() => {
      //     getUser.unsubscribe();
      //   })
      // ).subscribe(
      //   (res: ODataResponse) => {
      //     // console.log(res)
      //     // userLogged.setCurrentUser(user.token, this.username, res.value[0].Id, user.refresh_tokens)
      //   },
      //   (err) => {
      //     console.log(err);
      //     if(err) {
      //       userLogged.logout();
      //       let addRelatedContact = this.tblWorkerService.postRefreshToken(user.refresh_tokens).pipe(
      //         finalize(() => {
      //           addRelatedContact.unsubscribe();
      //         })
      //       ).subscribe(
      //         (res: any) => {
      //           console.log(res)
      //           // let userLogged: UserLogged = new UserLogged();
      //           userLogged.setCurrentUser(res.id_token, this.username, this.userId, user.refresh_tokens)
      //           // location.reload();
      //           this.ngOnInit();
      //         },
      //         (err) => {
      //           console.log(err);
      //         });
      //     }
      //   });
    }
  };

  logOut() {
    let userLogged: UserLogged = new UserLogged();
    userLogged.logout();
    this.router.navigate(['/login']);
    // this.username = '';
    return false;
  }

}
